import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Divider,
  Select,
  notification,
} from 'antd';
import Recaptcha from 'react-google-recaptcha';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const msTeamsWebHook =
  'https://outlook.office.com/webhook/ec829691-eab8-47f1-8e65-5278c158cea4@0c0766cb-245e-4bce-b4e8-226b47bf470d/IncomingWebhook/be0055d702f64a71b34e3aa9429c7cb1/4f87b529-eda4-4001-837c-dc393ef39a98';

const StyledDiv = styled.div`
  margin-top: 64px;
  min-height: calc(100vh - 72px - 64px);
  padding-top: 25px;
  padding-bottom: 25px;

  .blog-content {
    max-width: 750px;
    margin: auto;
    text-align: center;
    font-size: 1.15em;
    padding-right: 10px;
    padding-left: 10px;

    h1 {
      margin-bottom: 24px;
    }

    .ant-divider {
      border-top: 2px solid #f0f0f0;
    }

    .service-desc {
      font-size: 0.8em;
      margin-top: 4px;
    }

    .services {
      margin-top: 20px;
      margin-bottom: 20px;
      color: #003366;
    }
  }

  .hidden-input {
    .ant-form-item-control-input {
      min-height: 2px;
    }
  }
`;

function PartnerWithUsPage() {
  const recaptchaRef = useRef(null);
  const [isSending, setIsSending] = useState(false);
  const [form] = Form.useForm();

  return (
    <Layout>
      <SEO title="Partner With PRIME" />
      <StyledDiv>
        <div className="blog-content">
          <h1>Why sell with PRIME?</h1>
          <div>
            <p>
              At{' '}
              <a href="https://www.primephilippines.com/about">
                PRIME Philippines
              </a>
              , we take our clients' needs and considerations above all else. We
              take pride in:
            </p>
            <Row gutter={8} className="take-pride" justify="center">
              <Col
                xs={{ span: 22 }}
                sm={{ span: 22 }}
                md={{ span: 22 }}
                lg={{ span: 22 }}
                xl={{ span: 22 }}
                xxl={{ span: 18 }}
              >
                our <b>Customer-First Approach</b>, the{' '}
                <b>Client-Intuitive Processes</b> we utilize,and the solid{' '}
                <b>Communication Lines</b> we maintain
              </Col>
            </Row>
            <p style={{ marginTop: 14 }}>
              to ensure long-standing client relationships.
            </p>
          </div>

          <Divider />

          <p>
            In property selling, we understand that taking the <b>first step</b>{' '}
            and choosing to dispose your asset must have been a difficult
            decision to make, so PRIME Philippines believes in making the next
            steps to{' '}
            <a href="https://www.primephilippines.com/portfolio">
              find the right buyer
            </a>{' '}
            as convenient as possible.
          </p>

          <p>
            We offer{' '}
            <a href="https://www.primephilippines.com/our-expertise/experienced/end-to-end-real-estate-services">
              end-to-end services
            </a>{' '}
            for our clients:
          </p>

          <Row gutter={12} align="top" justify="center" className="services">
            <Col
              xs={{ span: 11 }}
              sm={{ span: 11 }}
              md={{ span: 5 }}
              lg={{ span: 5 }}
            >
              <FontAwesomeIcon icon="print" fixedWidth size="3x" />
              <div className="service-desc">Processing necessary paperwork</div>
            </Col>

            <Col
              xs={{ span: 11 }}
              sm={{ span: 11 }}
              md={{ span: 5 }}
              lg={{ span: 5 }}
            >
              <FontAwesomeIcon icon="bullhorn" fixedWidth size="3x" />
              <div className="service-desc">Marketing your property</div>
            </Col>
            <Col
              xs={{ span: 11 }}
              sm={{ span: 11 }}
              md={{ span: 5 }}
              lg={{ span: 5 }}
            >
              <FontAwesomeIcon icon="user-tie" fixedWidth size="3x" />
              <div className="service-desc">Screening potential buyers</div>
            </Col>
            <Col
              xs={{ span: 11 }}
              sm={{ span: 11 }}
              md={{ span: 5 }}
              lg={{ span: 5 }}
            >
              <FontAwesomeIcon icon="people-arrows" fixedWidth size="3x" />
              <div className="service-desc">Handling negotiations</div>
            </Col>
          </Row>

          <p style={{ marginTop: 14 }}>
            All these, ensuring as much as possible that you experience the most
            painless and hassle-free process.
          </p>

          <p>
            We at PRIME Philippines are ready to create the best possible value
            for your asset, so <AnchorLink href="#form">partner</AnchorLink>{' '}
            with us today!
          </p>

          <AnchorLink href="#form">
            <Button type="primary" size="large">
              <b>PARTNER WITH PRIME</b>
            </Button>
          </AnchorLink>

          <br id="form" />
          <br />
          <Divider />
          <br />

          <Row justify="center">
            <Col xs={24} sm={24} md={18} lg={16} xl={14}>
              <h3>Get in touch with us!</h3>
              <p>
                Tell us about your property and our sales team will assess and
                study on how you can maximize your investment.
              </p>
              <Form
                form={form}
                name="basic"
                layout="vertical"
                onFinish={(values) => {
                  setIsSending(true);
                  fetch(
                    `https://hephaestus-backend.herokuapp.com/email-requests`,
                    {
                      method: 'POST',
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        company_identifier: 'PRIMEPH-PROPWISE',
                        email_address: values.emailAddress,
                        subject: "Propwise Inquiry (SELLER'S PAGE)",
                        data: [
                          { key: 'Property Type', value: values.typeOfInquiry },
                          {
                            key: 'Fullname',
                            value: `${values.complateName}`,
                          },
                          { key: 'Email Address', value: values.emailAddress },
                          {
                            key: 'Contact Number',
                            value: values.contactNumber,
                          },
                          { key: 'Company Name', value: values.companyName },
                          { key: 'Job Title', value: values.jobTitle },
                          { key: 'Tell Us More', value: values.tellUsMore },
                        ],
                      }),
                    }
                  )
                    .then((response) => response.json())
                    .then(() => {
                      notification.info({
                        message: 'Message Sent!',
                        description:
                          "We'll get back to you once receipt of your inquiry.",
                      });
                      form.resetFields();
                    })
                    .catch(() => {
                      notification.error({
                        message: 'Something Went Wrong!',
                        description:
                          'There was probleming sending your inquiry. Please try again later.',
                      });
                    })
                    .finally(() => {
                      setIsSending(false);
                    });

                  fetch(msTeamsWebHook, {
                    method: 'POST',
                    mode: 'no-cors',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      '@context': 'https://schema.org/extensions',
                      '@type': 'MessageCard',
                      themeColor: '0072C6',
                      title: `Contact form (SELLER'S PAGE) - ${
                        values.complateName
                      } (${moment().format('LLL')})`,
                      text:
                        '* Property Type: ' +
                        values.typeOfInquiry +
                        '\n' +
                        '* Tell us more: ' +
                        values.tellUsMore +
                        '\n' +
                        '* Name: ' +
                        values.complateName +
                        '\n' +
                        '* Email: ' +
                        values.emailAddress +
                        '\n' +
                        '* Phone: ' +
                        values.contactNumber +
                        '\n' +
                        '* Company: ' +
                        values.companyName +
                        '\n' +
                        '* Job Title: ' +
                        values.jobTitle +
                        '\n',
                    }),
                  }).then(
                    (success) => {
                      console.log(success);
                    },
                    (failed) => {
                      console.log(failed);
                    }
                  );
                }}
                style={{ textAlign: 'left' }}
              >
                <Form.Item
                  label="Please Select the Property Type"
                  name="typeOfInquiry"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please select one from the dropdown.',
                    },
                  ]}
                >
                  <Select placeholder="Property Types">
                    <Select.Option value="Commercial">Commercial</Select.Option>
                    <Select.Option value="Industrial">Industrial</Select.Option>
                    <Select.Option value="Premium Residential">
                      Premium Residential
                    </Select.Option>
                    <Select.Option value="Office Space for Sale">
                      Office Space for Sale
                    </Select.Option>
                    <Select.Option value="Hotel and Resort">
                      Hotel and Resort
                    </Select.Option>
                    <Select.Option value="Island">Island</Select.Option>
                    <Select.Option value="Others">Others</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Complete name"
                  name="complateName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your complete Name.',
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Contact number"
                  name="contactNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your contact number.',
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Email address"
                  name="emailAddress"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email address.',
                    },
                    {
                      type: 'email',
                      message: 'You provided an incorrect email.',
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>

                <Form.Item label="Company" name="companyName">
                  <Input />
                </Form.Item>

                <Form.Item label="Job Title" name="jobTitle">
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Tell us what help you need for your property?"
                  name="tellUsMore"
                  rules={[
                    {
                      required: true,
                      message:
                        'Please tell us what help you need for your property.',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.TextArea />
                </Form.Item>

                <Recaptcha
                  ref={recaptchaRef}
                  sitekey="6LdehiUTAAAAALj4VhxGS0EEMsC6aech1yN8UFfk"
                  onChange={(value) => {
                    form.setFieldsValue({
                      recaptchaToken: value,
                    });
                  }}
                />

                <Form.Item
                  name="recaptchaToken"
                  rules={[
                    {
                      required: true,
                      message:
                        'Please validate recaptcha to prove you are a human!',
                    },
                  ]}
                  className="hidden-input"
                >
                  <Input hidden disabled />
                </Form.Item>

                <br />

                <Form.Item>
                  <Button
                    loading={isSending}
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: 8 }}
                  >
                    <b>SUBMIT</b>
                  </Button>

                  <Button
                    htmlType="button"
                    onClick={() => {
                      form.resetFields();
                    }}
                  >
                    <b>RESET</b>
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </StyledDiv>
    </Layout>
  );
}

export default PartnerWithUsPage;
